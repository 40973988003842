*, 
*:before, 
*:after {
  box-sizing: border-box;
} 

body {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  margin: 0;
  padding: 0;
}
